:root{
    --vermelho-jamp:rgb(185,58,62);
    --amarelo-jamp:rgb(255, 204,41);
    --cardsb:'jampti';
}

@font-face {
    font-family: jampti;
    src: url(../../fonts/GOTHICB.TTF)
    format(truetype);
}
.gothic{
    font-family:var(--cardsb);
    color: rgb(158, 155, 155);
}
.justify{
    text-align: justify;
}
.xl{
    font-size: 1.4pc;
}
.jamp-color{
    color: var(--vermelho-jamp);
}