@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --inter-font: 'Inter', sans-serif;
    --raleway-font:'Raleway', sans-serif;
}
.solucao{
    font-family: var(--inter-font);
    font-weight: 500;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(240, 13, 13, 0.438);
    font-size: 14em; color: #ff000063;
}