:root{
    --vermelho-jamp:rgb(185,58,62);
    --amarelo-jamp:rgb(255, 204,41);
    --cardsb:'jampti';
}
@font-face {
    font-family: jampti;
    src: url(../../fonts/GOTHICB.TTF)
    format(truetype);
}
.contant-cards{
    width: 170px;
    height: 250px;
    margin-top: 100px;
    
    
    
}
.container-cards{
    width:200PX;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-left: -30px;
    overflow: hidden;
     border: 1px solid red;
     
}
.container-cards:hover{
    
    height: 330px;
    
    transition: all;
    transition-duration: 1s;
}
.container-cards:hover .oculta{
    display: none;
    
}

.conteudo{
    width: 260px;
    height: 430px;
    color: azure;
    text-align:justify;
}

.titulo-black{
padding-top: 10px;
text-align: center;
color:var(--vermelho-jamp);
font-family: var(--cardsb);
font-size: 14px;
}
.titulo{
    margin-top: 20px;
    text-align: center;
    color: azure;
    font-size: 14px;
font-family: var(--cardsb);
    }
.img-icon-card{
  width: 50px;
  height: 50px;
  margin-left: 90px;
margin-top: 110px;
  transition: all;
  transition-duration: 1s;
 opacity: 0;
 
}
.img-icon-card-black{
    width: 70px;
    height: 70px;
    margin-left: 95px;
    margin-top: 150px;
    transition: all;
    transition-duration: 1s;
    
  }
.container-cards:hover .img-icon-card{
    margin-top:10px;
    opacity: 1;
} 
.descricao{
    padding: 0 40px ;
    color: azure;
    margin-top: 10px;
    text-align: justify;
    font-size: 0.6pc;
}

.hiden{
margin: 15px;
opacity: 0;
color: azure;
}
.container-cards:hover  .hiden , .saiba{
transition: all 2400ms;
transform-origin: bottom;
opacity: 1;

}
.container-cards:hover{
    background-color:var(--vermelho-jamp);
    
    
}
.saiba{
    text-decoration: none;
    font-weight: 700;
    color: azure;
    margin-top: -15px;
    margin-left: 40px;
    font-size: 0.8pc;
}
.saiba:hover  {
    
    color:var(--amarelo-jamp);
    text-decoration: underline var(--amarelo-jamp) 4px;
    
}
.saiba:hover .red-off {
    
    display: flex;
}
.red-off{
    display: none;
}

.right-arow{
    width: 10px;
    height: 10px; 
    margin-top: -1px;
    margin-left: 4px;
}
@media only screen and (max-width: 400px) {
    
  }
  