.banner{
    display:flex;
    
    justify-content: center;
    align-items: center;
    height: 300px;
    
}
.banner-card{
    width:auto;
    height: 250px;
    margin: 70px auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    
}
.banner-card > p{
    width: 70%;
    margin: 0 auto;
    font-size: 1.4pc;
    line-height: 35px;
    font-family: var(--kanit) ;
    font-weight: 500;
    color: rgb(158, 155, 155);
}
.banner-card > p > strong{
   color: rgb(221, 44, 13);
}
.container-social{
    width: 160px;
    height: 300px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: -80px;
    margin-top:20px;
    
}
.social{
    width: 58px;
    height: 58px;
    background-image: url(../../img/social-icons/facebook-vermelho.png);
    background-repeat: no-repeat;
    background-position: center;
    object-fit:fill;
    
}
.social1{
    width: 58px;
    height: 58px;
    background-image: url(../../img/social-icons/insta-vermelho.png);
    background-repeat: no-repeat;
    background-position: center;
    object-fit:fill;
    
    
}
.social2{
    width: 64px;
    height: 58px;
    background-image: url(../../img/social-icons/canal-vermelho.png);
    background-repeat: no-repeat;
    background-position: center;
    object-fit:fill;
    
    
}
.social3{
    width: 58px;
    height: 58px;
    background-image: url(../../img/social-icons/linkedin-vermelho48.png);
    background-repeat: no-repeat;
    background-position: center;
    object-fit:fill;
    
    
}
.social:hover{
    width: 58px;
    height: 58px;
    background-image: url(../../img/social-icons/facebook-amarelo.png);
    background-repeat: no-repeat;
    background-position: center;
    object-fit:fill;
    
}
.social1:hover{
    width: 58px;
    height: 58px;
    background-image: url(../../img/social-icons/insta-amarelo.png);
    background-repeat: no-repeat;
    background-position: center;
    object-fit:fill;
    
    
}
.social2:hover{
    width: 58px;
    height: 58px;
    background-image: url(../../img/social-icons/canal-amarelo.png);
    background-repeat: no-repeat;
    background-position: center;
    object-fit:fill;
    
    
}
.social3:hover{
    width: 58px;
    height: 58px;
    background-image: url(../../img/social-icons/linkedin-amarelo.png);
    background-repeat: no-repeat;
    background-position: center;
    object-fit:fill;
    
    
}
.font{
    font-size: 16px;
}

@media only screen and (max-width: 600px) {
   
    .container-social{
        width: 160px;
        height: 300px;
        display:flex;
        flex-direction:column;
        justify-content: flex-start;
        align-items: center;
        margin-left: 10px;
        margin-top:50px;
        padding: 6px;
        
    }
    .banner-card > p{
        width: 70%;
        margin: 0 auto;
        font-size: 1.4pc;
        line-height: 23px;
        font-family: var(--kanit) ;
        font-weight: 500;
        color: rgb(158, 155, 155);
    }
    

  }