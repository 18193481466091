:root{
    --vermelho-jamp:rgba(185, 58, 62, 0.555);
    --text-color:rgb(168, 166, 166);
    --amarelo-jamp:rgb(255, 204,41);
    --cardsb:'jampti';
}
@font-face {
    font-family: jampti;
    src: url(../fonts/GOTHICB.TTF)
    format(truetype);
}

.gohtic{
   font-family:var(--cardsb);
   font-size:18px;

}