@import url('https://fonts.googleapis.com/css2? família= Inconsolata:wght@200;300;400;500;600;700;800;900 &family= Raleway:ital,wght@1.100 &família= Roboto+Slab:wght@100;200;300;400;500;600;700;800;900 & display=swap');
:root{
    --font-foot:'Raleway', sem serifa;
    --links: 'Inconsolata', monoespaçado;
    --titulos: 'Roboto Slab', serif;
    --vermelho-jamp:rgba(185, 58, 62, 0.555);
    --text-color:rgb(168, 166, 166);
    --amarelo-jamp:rgb(255, 204,41);
}
.reseved{
  font-family: var(--font-foot);
  font-size: 11px;
}
.end{
  font-size: 21px;
}
.link-left{
   margin-left: -14px; 
}
.aling-iten{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.end-left{
    margin-left: -30px;
    font-size: 15px;
}
.link-add{
  text-decoration: none;
  color: black;
  padding: 2px;
}
.link-add:hover{
  color: var(--vermelho-jamp);
}