@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Secular+One&display=swap');
:root{
  --vermelho-jamp:rgba(185, 58, 62, 0.555);
  --text-color:rgb(168, 166, 166);
  --amarelo-jamp:rgb(255, 204,41);
  --cardsb:'jampti';
  --Raleway: 'Secular One', sans-serif;
}
@font-face {
  font-family: jampti;
  src: url(../../fonts/GOTHICB.TTF)
  format(truetype);
}

.gohtic{
 font-family:var(--Raleway);
 font-size:1.8pc;

}

@media only screen and (max-width: 500px) {
  .gohtic{
    font-family:var(--Raleway);
    font-size:1.2pc;
   
   }
}