@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

:root{
    --vermelho-jamp:rgb(185,58,62);
    --amarelo-jamp:rgb(255, 204,41);
    --cardsb:'jampti';
}
@font-face {
    font-family: jampti;
    src: url(../../fonts/GOTHICB.TTF)
    format(truetype);
}
.contant-cardsb{
    width: 120px;
    height: 250px;
   
    
    
    
}
.container-cardsb{
    width:200PX;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-left: -30px;
    overflow: hidden;
     border: 1px solid red;
     
}
.container-cardsb:hover{
    
    height: 330px;
    
    transition: all;
    transition-duration: 1s;
}
.container-cardsb:hover .ocultab{
    display: none;
    
}

.conteudob{
    width: 260px;
    height: 430px;
    color: azure;
    text-align:justify;
}

.titulo-blackb{
padding-top: 10px;
text-align: center;
color:var(--vermelho-jamp);
font-family: var(--cardsb);
font-size: 14px;
}
.titulob{
    margin-top: 20px;
    text-align: center;
    color: azure;
    font-size: 14px;
font-family: var(--cardsb);
    }
.img-icon-cardb{
  width: 90px;
  height: 90px;
  margin-left: 70px;
margin-top: 110px;
  transition: all;
  transition-duration: 1s;
 opacity: 0;

}
.img-icon-card-blackb{
    width: 90px;
    height: 80px;
    margin-left: 90px;
    margin-top: 150px;
    transition: all;
    transition-duration: 1s;
    
  }
.container-cardsb:hover .img-icon-cardb{
    margin-top:10px;
    opacity: 1;
} 
.descricaob{
    padding: 0 40px ;
    color: azure;
    margin-top: 10px;
    text-align: justify;
    font-size: 0.8pc;
}

.hidenb{
margin: 15px;
opacity: 0;
color: azure;
}
.container-cardsb:hover  .hidenb , .saibab{
transition: all 2400ms;
transform-origin: bottom;
opacity: 1;

}
.container-cardsb:hover{
    background-color:var(--vermelho-jamp);
    
    
}
.saibab{
    text-decoration: none;
    font-weight: 700;
    color: azure;
    margin-top: -15px;
    margin-left: 40px;
    font-size: 0.8pc;
}
.saibab:hover  {
    
    color:var(--amarelo-jamp);
    text-decoration: underline var(--amarelo-jamp) 4px;
    
}
.saibab:hover .red-offb {
    
    display: flex;
}
.red-offb{
    display: none;
}

.right-arowb{
     width: 10px;
    height: 10px; 
    margin-top: -1px;
    margin-left: 4px;
}
@media only screen and (max-width: 400px) {
    
  }